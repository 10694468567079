import { useState, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useRouter } from 'next/router';
import { makeStyles } from 'tss-react/mui';

// context
import { GlobalContext } from 'context';
import logo from '/public/svg/upartLogo.svg';
import BGimage from '/public/Image/HeadImg.png';
import Button from '/components/global/form/Button';
import Input from '/components/global/form/Input';

// actions
import { login } from 'context/actions/auth';

const useStyles = makeStyles()(() => {
    return {
        root: {},
        container: {
            backgroundColor: 'gray',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            backgroundImage: `url(${BGimage.src})`,
            backgroundSize: 'cover'
        },
        inputBox: {
            backgroundColor: 'white',
            justifyItems: 'center',
            display: 'grid',
            borderRadius: 5,
            width: 350,
            height: 500,
            alignContent: 'center'
        },
        subTitle: {},
        logo: {
            width: 69,
            height: 80,
            backgroundImage: `url(${logo.src})`,
            backgroundSize: 'cover',
            marginBottom: 50
        },
        alert: {
            marginTop: 10,
            width: '240px'
        },
        confirmCode: {
            //  backgroundColor: 'red',
            padding: '0px !important'
        },
        passSHow: {
            color: 'gray',
            paddingTop: 15,
            margin: 'auto',
            cursor: 'pointer',
            textAlign: 'center',
            width: 'fit-content',
            '&:hover': {
                color: 'black'
            }
        },
        desctn: {
            color: 'gray',
            // marginTop: 15,
            paddingBottom: 5,
            width: 170,
            margin: 'auto',
            textAlign: 'center',
            lineHeight: 1.2
        }
    };
});

const Login = (props) => {
    const { classes } = useStyles();
    const router = useRouter();
    const { dispatch } = useContext(GlobalContext);
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [alert, setAlert] = useState({ show: false, message: '' });
    const [type, setType] = useState(props.type ? props.type : 'login');

    const handleLoginType = () => {
        setType('login');
    };

    const handleLogin = async () => {
        if (!phone.length || !password.length) {
            setAlert({
                show: true,
                message: 'Нэвтрэх нэр болон нууц үгээ оруулна уу!'
            });
            return;
        }

        setAlert({ show: false, message: '' });

        const result = await login(dispatch, { phone, password });
        if (!result.success) {
            setAlert({
                show: true,
                message: result.message
            });
        } else {
            clearAlert();
            if (result.data.info.role === 'SUPPLIER') router.push('/supplier');
            else if (result.data.info.role === 'ADMIN') router.push('/supplier');
        }
    };

    const handleEmailCode = async () => {
        setType('forgot');
    };

    const handleConfirmCode = async () => {
        setType('confirm_code');
    };

    const handleNewPass = async () => {
        setType('new_Pass');
    };

    const handleUserNameChange = (e) => setPhone(e.target.value);
    const handlePaswordChange = (e) => setPassword(e.target.value);
    const clearAlert = () => {
        setAlert({
            show: false,
            message: null
        });
    };

    if (type === 'login') {
        return (
            <Box className={classes.inputBox}>
                <Box className={classes.logo}></Box>
                <Box>
                    <Input
                        placeholder="Нэвтрэх нэр"
                        width={240}
                        value={phone}
                        onChange={handleUserNameChange}
                        size="small"
                        className={classes.customTextField}
                    />
                    <Input
                        placeholder="Нууц үг"
                        width={240}
                        type="password"
                        value={password}
                        onChange={handlePaswordChange}
                        size="small"
                        className={classes.customTextField}
                    />
                    {alert.show && (
                        <Alert severity="error" variant="outlined" className={classes.alert}>
                            {alert.message}
                        </Alert>
                    )}
                    <Button
                        title="Нэвтрэх"
                        width="100%"
                        marginTop="10px"
                        padding="9px"
                        onClick={handleLogin}
                    />
                    <Typography variant="h6" className={classes.passSHow} onClick={handleEmailCode}>
                        Нууц үг сэргээх
                    </Typography>
                </Box>
            </Box>
        );
    } else if (type === 'forgot') {
        return (
            <Box className={classes.inputBox}>
                <Box className={classes.logo}></Box>
                <Box>
                    <Typography variant="h6" className={classes.desctn}>
                        Та баталгаажуулах код хүлээн авах утасны дугаараа оруулна уу.
                    </Typography>
                    <Input
                        placeholder="Утасны дугаар"
                        width={240}
                        value={phone}
                        onChange={handleUserNameChange}
                        size="small"
                        type="number"
                        className={classes.customTextField}
                    />
                    <Button
                        title="Үргэжлүүлэх"
                        width="100%"
                        marginTop="10px"
                        padding="9px"
                        onClick={handleConfirmCode}
                    />
                    <Typography variant="h6" className={classes.passSHow} onClick={handleLoginType}>
                        Буцах
                    </Typography>
                </Box>
            </Box>
        );
    } else if (type === 'confirm_code') {
        return (
            <Box className={classes.inputBox}>
                <Box className={classes.logo}></Box>
                <Box>
                    <Typography variant="h6" className={classes.desctn}>
                        90990929 утасны дугаарт илгээсэн баталгаажуулах код оруулна уу.
                    </Typography>
                    <Box
                        style={{
                            display: 'flex',
                            width: 240,
                            justifyContent: 'space-between'
                        }}>
                        <Input
                            placeholder="0"
                            width={52}
                            value={phone}
                            onChange={handleUserNameChange}
                            size="small"
                            padding="0px 0px 0px 20px"
                            fontSize="18px"
                            type="number"
                            className={classes.confirmCode}
                        />
                        <Input
                            placeholder="0"
                            width={52}
                            value={phone}
                            onChange={handleUserNameChange}
                            size="small"
                            padding="0px 0px 0px 20px"
                            fontSize="18px"
                            type="number"
                            className={classes.confirmCode}
                        />
                        <Input
                            placeholder="0"
                            width={52}
                            value={phone}
                            onChange={handleUserNameChange}
                            size="small"
                            padding="0px 0px 0px 20px"
                            fontSize="18px"
                            type="number"
                            className={classes.confirmCode}
                        />
                        <Input
                            placeholder="0"
                            width={52}
                            value={phone}
                            onChange={handleUserNameChange}
                            size="small"
                            padding="0px 0px 0px 20px"
                            fontSize="18px"
                            type="number"
                            className={classes.confirmCode}
                        />
                    </Box>
                    <Button
                        title="Үргэжлүүлэх"
                        width="100%"
                        marginTop="10px"
                        padding="9px"
                        onClick={handleNewPass}
                    />
                    <Typography variant="h6" className={classes.passSHow} onClick={handleEmailCode}>
                        Буцах
                    </Typography>
                </Box>
            </Box>
        );
    } else if (type === 'new_Pass') {
        return (
            <Box className={classes.inputBox}>
                <Box className={classes.logo}></Box>
                <Box>
                    <Input
                        placeholder="Нууц үг"
                        width={240}
                        // value={mobile}
                        onChange={handleUserNameChange}
                        size="small"
                        className={classes.customTextField}
                    />
                    <Input
                        placeholder="Нууц үг давтах"
                        width={240}
                        type="password"
                        value={password}
                        onChange={handlePaswordChange}
                        size="small"
                        className={classes.customTextField}
                    />
                    {alert.show && (
                        <Alert severity="error" variant="outlined" className={classes.alert}>
                            {alert.message}
                        </Alert>
                    )}
                    <Button
                        title="Хадгалах"
                        width="100%"
                        marginTop="10px"
                        padding="9px"
                        onClick={handleEmailCode}
                    />
                    <Typography
                        variant="h6"
                        className={classes.passSHow}
                        onClick={handleConfirmCode}>
                        Буцах
                    </Typography>
                </Box>
            </Box>
        );
    }
};

// Login.Layout = LoginLayout;
export default Login;
