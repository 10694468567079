const styles = () => ({
    root: {
        width: '100%',
        borderRadius: 5,
        fontWeight: 600,
        color: 'white',
        '&:hover': {
            backgroundColor: '#114499'
        }
    }
});

export default styles;
