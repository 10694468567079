import React from 'react';
// import { makeStyles } from '@mui/styles';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles()(() => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: 4
        },
        title: {
            color: '#949494',
            // marginBottom: 5,
            fontSize: 12,
            fontWeight: 600
        },
        input: {
            flex: 1,
            height: 30,
            width: '100%',
            color: 'black',
            paddingLeft: 12,
            // background: '#F6F6F6',
            border: '1px solid #b2b2b280',
            borderRadius: 5,
            boxSizing: 'border-box',
            fontWeight: 500,
            fontSize: 13,
            '&:focus': {
                border: '1px'
                // outline: 'none',
            },
            '&::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none'
                // margin: 0,
            },
            '&::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none'
                // margin: 0,
            }
        },

        disabled: {
            backgroundColor: '#f4f4f4'
        }
    };
});

const CustomInputBox = (props) => {
    const { classes } = useStyles();

    const onChange = (e) => {
        props.onChange(e, props.keyName);
    };
    const onFocus = (e) => {
        props.onFocus(e, props.keyName);
    };
    const onKeyDown = (e) => {
        props.onKeyDown(e, props.keyName);
    };

    return (
        <Box
            // component="form"
            className={[classes.root, props.className]}
            style={{
                width: props.width,
                flexDirection: props.row,
                alignItems: props.center,
                margin: props.title ? '10 0px 0px 0px' : '6px 0px 0px 0px'
            }}>
            {props.title !== '' ? (
                <Box style={{ display: 'flex', height: 12 }}>
                    {props.require ? (
                        <Typography
                            variant="h4"
                            style={{ color: 'red', marginRight: 3, marginLeft: 0 }}>
                            *
                        </Typography>
                    ) : (
                        ''
                    )}
                    <Typography variant="h4" className={classes.title}>
                        {props.title}
                    </Typography>
                </Box>
            ) : (
                ''
            )}
            <Box>
                <input
                    placeholder={props.placeholder}
                    onChange={onChange}
                    onFocus={onFocus}
                    onKeyDown={onKeyDown}
                    style={{
                        height: props.height ? props.height : 30,
                        margin: props.margin,
                        padding: props.padding,
                        textAlign: props.textAlign,
                        fontSize: props.fontSize
                    }}
                    // onKeyDown={handleKeyDown}
                    value={props.value}
                    type={props.type}
                    disabled={props.mode}
                    className={clsx({
                        [classes.input]: true,
                        [classes.disabled]: props.mode
                    })}
                    maxLength={props.maxLength}
                />
            </Box>
        </Box>
    );
};

CustomInputBox.defaultProps = {
    width: 282,
    keyName: 'name',
    require: false,
    value: '',
    title: '',
    placeholder: '',
    onChange: () => {},
    onFocus: () => {},
    onKeyDown: () => {},
    height: 30,
    type: null,
    mode: false,
    row: 'column',
    center: '',
    maxLength: 500
};
export default CustomInputBox;
