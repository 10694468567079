import { useState, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Box, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { makeStyles } from 'tss-react/mui';
import LoginLayout from 'layouts/LoginLayout';

// context
import { GlobalContext } from 'context';
import BGimage from '/public/Image/HeadImg.png';
import LoginPop from 'components/local/layout/LoginPop';

// actions
import { login } from 'context/actions/auth';

const useStyles = makeStyles()(() => {
    return {
        root: {},
        container: {
            backgroundColor: 'gray',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            backgroundImage: `url(${BGimage.src})`,
            backgroundSize: 'cover'
        }
    };
});

const Login = () => {
    const { classes } = useStyles();
    const { state, dispatch } = useContext(GlobalContext);
    const router = useRouter();
    const [login, setLogin] = useState(false);
    const [mobile, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [alert, setAlert] = useState({ show: false, message: '' });
    useEffect(() => {
        if (state.auth.loggedIn) {
            router.push('/supplier');
        } else setLogin(true);

        //if (state.auth.user.role === 'ADMIN') router.push('/supplier');
        //return;
        // else router.push('/admin/news');
    }, [state.auth]);

    const handleLogin = async () => {
        if (!(mobile.length || password.length)) {
            setAlert({
                show: true,
                message: 'Нэвтрэх нэр, нууц үгээ бүрэн оруулна уу'
            });
            return;
        }

        setAlert({ show: false, message: '' });

        const result = await login(dispatch, { mobile, password });

        if (!result.success) {
            setAlert({
                show: true,
                message: result.message
            });
        }
    };

    return <Box className={login ? classes.container : classes.root}>{login && <LoginPop />}</Box>;
};

Login.Layout = LoginLayout;
export default Login;
