import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => {
    return {
        root: {
            height: '100vh',
            backgroundColor: 'white'
        }
    };
});

export default function LoginLayout({ children }) {
    const { classes } = useStyles();

    return (
        <main>
            <div className={classes.root}>{children}</div>
        </main>
    );
}
