import React from 'react';
import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import styles from './Button.styles';

const CustomButton = (props) => {
    return (
        <Button
            style={{
                marginLeft: props.left,
                width: props.width,
                backgroundColor: props.BGcolor,
                color: props.color,
                marginTop: props.marginTop,
                padding: props.padding
            }}
            onClick={props.onClick}
            variant="contained"
            type="submit">
            {props.title}
        </Button>
    );
};

CustomButton.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    color: PropTypes.string,
    BGcolor: PropTypes.string,
    marginTop: PropTypes.string,
    padding: PropTypes.string
};

CustomButton.defaultProps = {
    title: 'Нэмэх',
    color: 'white',
    BGcolor: '#2D2D2D',
    onClick: null
};

export default withStyles(styles)(CustomButton);
